import React from "react"
import { graphql } from "gatsby"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import { Layout, Header, Container, Stack, SEO } from "../components"

const PageImage = ({ image }) => {
  return (
    <div
      sx={{
        height: [220, 460],
        overflow: "hidden",
        borderRadius: [0, "big"],
      }}
    >
      <Img fluid={image.childImageSharp.fluid} style={{ height: "100%" }}></Img>
    </div>
  )
}

const PageBanner = ({ title }) => {
  return (
    <div
      sx={{
        backgroundColor: "gray.950",
        height: [225, 400],
      }}
    >
      <Container sx={{ position: "relative", top: "50%" }}>
        <Styled.h1 sx={{ color: "gray.50" }}>{title}</Styled.h1>
      </Container>
    </div>
  )
}

const MDXPage = ({ data: { mdx } }) => {
  const { title, image, lang } = mdx.frontmatter

  return (
    <MDXProvider>
      <SEO title={title} lang={lang}></SEO>
      <Layout>
        <div sx={{ paddingY: [40, 72] }}>
          <Stack>
            <Header />
            <Container
              sx={t => ({
                maxWidth: t.sizes.container + 64,
                paddingX: [0, 20],
              })}
            >
              {image && <PageImage image={image} />}
            </Container>
            {!image && <PageBanner title={title} />}
            <Container>
              <Stack
                sx={t => ({
                  "*": { maxWidth: "725px" },
                  "img, iframe, [data-maxWidth='full']": {
                    maxWidth: t.sizes.container + 64,
                  },
                })}
              >
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </Stack>
            </Container>
          </Stack>
        </div>
      </Layout>
    </MDXProvider>
  )
}

export default MDXPage

export const pageQuery = graphql`
  query MDXPage($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        lang
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
